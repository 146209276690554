import { validate } from '../../main/validate';

import { NEWS_ADMIN_CREATE_DATA_NAME } from './news-admin-create.constant';

import {
  requiredArray,
  required,
  arrayLengthMax,
  maxLength,
  numberPositive,
  url,
} from '../../main/validate/validate.service';

const config = {
  [NEWS_ADMIN_CREATE_DATA_NAME.ORDER]: [required, numberPositive],
  [NEWS_ADMIN_CREATE_DATA_NAME.PREVIEW]: [
    required,
    requiredArray,
    arrayLengthMax(1),
  ],
  [NEWS_ADMIN_CREATE_DATA_NAME.TITLE]: [required, maxLength(500)],
  [NEWS_ADMIN_CREATE_DATA_NAME.URL]: [required, url, maxLength(500)],
  [NEWS_ADMIN_CREATE_DATA_NAME.BODY]: [required, maxLength(20000)],
  [NEWS_ADMIN_CREATE_DATA_NAME.CATEGORY]: [required],
  [NEWS_ADMIN_CREATE_DATA_NAME.DESCRIPTION]: [required, maxLength(500)],
  [NEWS_ADMIN_CREATE_DATA_NAME.AUTHOR]: [required, maxLength(500)],
  [NEWS_ADMIN_CREATE_DATA_NAME.LANG]: [required],
};

export const newsAdminCreateFormValidation = (values) =>
  validate(values, config);
