import { NEWS_ADMIN_LIST_DATA_NAME } from "./news-admin-list.constant";

import {
  convertNewsStatus,
  convertNewsCategory,
} from "../../lib/common/news/news.convert";
import { convertDatetime } from "../../lib/common/convert/convert.core";

export const convertNewsAdminList = (d) => ({
  [NEWS_ADMIN_LIST_DATA_NAME.LIST]: d[[NEWS_ADMIN_LIST_DATA_NAME.LIST]].map(
    (data) => {
      return {
        [NEWS_ADMIN_LIST_DATA_NAME.TITLE]:
          data[NEWS_ADMIN_LIST_DATA_NAME.TITLE],
        [NEWS_ADMIN_LIST_DATA_NAME.ID]: data[NEWS_ADMIN_LIST_DATA_NAME.ID],
        [NEWS_ADMIN_LIST_DATA_NAME.ORDER]:
          data[NEWS_ADMIN_LIST_DATA_NAME.ORDER],
        [NEWS_ADMIN_LIST_DATA_NAME.URL]: data[NEWS_ADMIN_LIST_DATA_NAME.URL],
        [NEWS_ADMIN_LIST_DATA_NAME.CREATE_DATE]: convertDatetime(
          data[NEWS_ADMIN_LIST_DATA_NAME.CREATE_DATE]
        ),
        [NEWS_ADMIN_LIST_DATA_NAME.STATUS]: convertNewsStatus(
          data[NEWS_ADMIN_LIST_DATA_NAME.STATUS]
        ),
        [NEWS_ADMIN_LIST_DATA_NAME.CATEGORY]: convertNewsCategory(
          data[NEWS_ADMIN_LIST_DATA_NAME.CATEGORY]
        ),
      };
    }
  ),
  [NEWS_ADMIN_LIST_DATA_NAME.SKIP]: d[[NEWS_ADMIN_LIST_DATA_NAME.SKIP]],
  [NEWS_ADMIN_LIST_DATA_NAME.TAKE]: d[[NEWS_ADMIN_LIST_DATA_NAME.TAKE]],
  [NEWS_ADMIN_LIST_DATA_NAME.AMOUNT]: d[[NEWS_ADMIN_LIST_DATA_NAME.AMOUNT]],
  [NEWS_ADMIN_LIST_DATA_NAME.STATUS]: d[[NEWS_ADMIN_LIST_DATA_NAME.STATUS]],
  [NEWS_ADMIN_LIST_DATA_NAME.CATEGORY]: d[[NEWS_ADMIN_LIST_DATA_NAME.CATEGORY]],
  [NEWS_ADMIN_LIST_DATA_NAME.LANG]: d[[NEWS_ADMIN_LIST_DATA_NAME.LANG]],
  [NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER]:
    d[[NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER]],
  [NEWS_ADMIN_LIST_DATA_NAME.SORT_ID]: d[[NEWS_ADMIN_LIST_DATA_NAME.SORT_ID]],
});
